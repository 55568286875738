import cn from 'classnames';
import NavItem from 'components/nav-item';
import { useCollectionState } from 'modules/collection/state';
import Image from 'next/image';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement, unselectElement } from 'tracking';

interface FilterButtonProps {
  filterName: string;
}

/**
 * Button for the hero filter component.
 * With memo, only the affected filter button will be re-rendered, if a button is updated,
 * reducing the overall rendering time.
 */
const HeroFilterButton: React.FC<FilterButtonProps> = memo(({ filterName }) => {
  const { appliedFilter, toggleFilter } = useCollectionState();

  const handleToggleFilter = useCallback(() => {
    toggleFilter(filterName);
    gtm(
      (appliedFilter.includes(filterName) ? unselectElement : selectElement)({
        element: ['Hero Filter', filterName],
      })
    );
  }, [appliedFilter, toggleFilter, filterName]);

  return (
    <NavItem
      onClick={handleToggleFilter}
      selected={appliedFilter.includes(filterName)}
      className="col-span-3 !px-2 !py-1 lg:justify-start lg:!py-1.5"
    >
      <div
        className={cn(
          'flex flex-col items-center justify-center gap-1 lg:flex-row'
        )}
      >
        <div className="h-[48px] w-[48px] lg:h-[30px] lg:w-[30px]">
          <Image
            src={`/images/collection/hero-filter/hero_filter_${filterName}.png`}
            alt={filterName}
            width={96}
            height={96}
          />
        </div>
        <p className="text-xs lg:text-sm">
          <FormattedMessage id={`common:hero-filter:${filterName}`} />
        </p>
      </div>
    </NavItem>
  );
});

HeroFilterButton.displayName = 'HeroFilterButton';

export default HeroFilterButton;
