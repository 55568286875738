import cn from 'classnames';
import RibbonText, { RibbonTextProps } from 'components/ribbon-text';
import { FormattedMessage } from 'react-intl';

interface HeadlineProps {
  classNames?: string;
  extraElement?: React.ReactNode;
  ribbonColor?: RibbonTextProps['color'];
  ribbonCommonStyleOverride?: React.CSSProperties;
  shouldBeH1?: boolean;
  subTitle?: string;
  title: string;
  titleClassName?: string;
  useMindsetFont?: boolean;
}

const renderTitle = ({
  title,
  attributes,
  shouldBeH1,
}: {
  attributes?: React.HTMLAttributes<HTMLHeadingElement>;
  title: React.ReactNode;
  shouldBeH1: boolean;
}): React.ReactNode => {
  return shouldBeH1 ? (
    <h1 {...attributes}>{title}</h1>
  ) : (
    <h2 {...attributes}>{title}</h2>
  );
};

const Headline: React.FC<HeadlineProps> = ({
  classNames,
  extraElement,
  ribbonColor = 'yellow',
  shouldBeH1 = true,
  subTitle,
  title,
  useMindsetFont,
  ribbonCommonStyleOverride,
}) => (
  <>
    {subTitle && (
      <p className="text-sm leading-[17.50px]">
        <FormattedMessage id={subTitle} />
      </p>
    )}
    <div className={cn('items-baseline gap-2 lg:flex lg:gap-3', classNames)}>
      {renderTitle({
        title: (
          <FormattedMessage
            id={title}
            values={{
              RibbonText: (chunks) => (
                <RibbonText
                  color={ribbonColor}
                  commonStyleOverride={ribbonCommonStyleOverride}
                >
                  {chunks}
                </RibbonText>
              ),
            }}
          />
        ),
        attributes: {
          className: cn(
            useMindsetFont
              ? 'font-mindset tracking-wider lg:leading-[51px] font-normal text-2xl lg:text-[40px] leading-[30px]'
              : 'lg:text-2xl text-base font-black lg:leading-loose leading-7'
          ),
        },
        shouldBeH1,
      })}
      {extraElement}
    </div>
  </>
);

export default Headline;
