import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCollectionState } from '../../state';

const ResultCounter: React.FC = () => {
  const { productsLength } = useCollectionState();

  return (
    <div className="text-xs leading-4 text-gray-500">
      <FormattedMessage
        id="cdp:general:products"
        values={{
          amount: productsLength,
        }}
      />
    </div>
  );
};

export default ResultCounter;
