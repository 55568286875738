import cn from 'classnames';
import useMobile from 'hooks/common/use-mobile';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ElementEvent, gtm, selectElement, unselectElement } from 'tracking';
import { slugify } from 'utils/strings';
import SmallCaps from '../../../../components/typography/small-caps';
import {
  CollectionFilter,
  FilterConfig,
  possibleTags,
} from '../../../../interfaces/collection-filter';
import { useCollectionState } from '../../state';
import { useAvailableFilterMap } from '../../state/available-choices';
import styles from './filter-group.module.css';

interface IFilterGroupProps {
  exclusiveFilter: string[];
  fakeFilters: string[];
  filterConfig: FilterConfig;
  setExclusiveFilter: React.Dispatch<React.SetStateAction<string[]>>;
  setFakeFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * Component that renders a the filters grouped by the filter key.
 * AT the moment we use "fake filters" to test the following fake door test.
 * https://petsdeli.atlassian.net/browse/PT-5632
 * These filters just track the user interaction and don't filter the products.
 */
const FilterGroup: React.FC<IFilterGroupProps> = ({
  exclusiveFilter,
  fakeFilters = [],
  filterConfig,
  setExclusiveFilter,
  setFakeFilters,
}) => {
  const { appliedFilter, visibleProducts, collection, toggleFilter } =
    useCollectionState();
  const isMobile = useMobile();

  const availableFilterMap = useAvailableFilterMap(collection, filterConfig);

  const visibleTagsSet = useMemo(
    () =>
      new Set(
        visibleProducts.flatMap((product) =>
          product.tags.map((tag) => slugify(tag))
        )
      ),
    [visibleProducts]
  );

  const isFilterAvailable = useCallback(
    (value: possibleTags, filterKey: string): boolean => {
      if (exclusiveFilter.includes(filterKey)) {
        // If there is already an active filter for this filterKey, disable all other filters
        return appliedFilter.includes(value);
      } else {
        return visibleTagsSet.has(value);
      }
    },
    [exclusiveFilter, visibleTagsSet, appliedFilter]
  );

  const isFakeFilterAvailable = useCallback(
    (value: string, filterKey: string): boolean => {
      if (exclusiveFilter.includes(filterKey)) {
        // If there is already an active filter for this filterKey, disable all other filters
        if (
          fakeFilters.some(
            (filter) => filter !== value && exclusiveFilter.includes(filter)
          )
        ) {
          return false;
        }
        return fakeFilters.includes(value);
      } else {
        return true;
      }
    },
    [exclusiveFilter, fakeFilters]
  );
  const toggleFakeFilter = useCallback(
    (value: string) => {
      if (fakeFilters.includes(value)) {
        setFakeFilters(fakeFilters.filter((filter) => filter !== value));
      } else {
        setFakeFilters([...fakeFilters, value]);
      }
    },
    [fakeFilters, setFakeFilters]
  );

  if (!Object.keys(availableFilterMap)) {
    return null;
  }

  return (
    <>
      {/* Duplicate filters for the fake door test */}
      {/* This needs to be removed or implemented after the test is finisehd */}
      {Object.keys(availableFilterMap)
        .filter((filterKey) => availableFilterMap[filterKey].tags.length > 0)
        .filter((filterKey) => filterKey !== 'hidden')
        .filter((filterKey) => filterKey !== 'hero-filter')
        // Show only filters that are for the fake door test
        // @see https://petsdeli.atlassian.net/browse/PT-5632
        .filter((filterKey) => filterKey.includes('fake'))
        .map((filterKey) => (
          <div key={filterKey} className="mb-6">
            <SmallCaps>
              <div className="mb-4 lg:mb-3">
                <FormattedMessage
                  id={'cdp:filter:' + filterKey}
                  defaultMessage={filterKey}
                />
              </div>
            </SmallCaps>
            <ul>
              {availableFilterMap[filterKey as CollectionFilter]?.tags.map(
                (value) => (
                  <li key={value} className="mb-4 text-sm last:mb-0">
                    <label
                      className={cn(
                        'flex items-center',
                        !isFakeFilterAvailable(value, filterKey)
                          ? 'cursor-default text-gray-400'
                          : 'cursor-pointer text-gray-800'
                      )}
                    >
                      <input
                        type="checkbox"
                        checked={fakeFilters.includes(value)}
                        disabled={!isFakeFilterAvailable(value, filterKey)}
                        onChange={() => {
                          const specialFilters = [
                            'nutritional-needs-fake',
                            'dog-breeds-fake',
                            'age-fake',
                          ];
                          if (specialFilters.includes(filterKey)) {
                            if (exclusiveFilter.includes(filterKey)) {
                              setExclusiveFilter(
                                exclusiveFilter.filter(
                                  (filter) => filter !== filterKey
                                )
                              );
                            } else {
                              setExclusiveFilter([
                                ...exclusiveFilter,
                                filterKey,
                              ]);
                            }
                          }

                          toggleFakeFilter(value);

                          const eventActionSelect = (): ElementEvent =>
                            selectElement({
                              element: ['Filter', filterKey, value],
                            });

                          const eventActionUnselect = (): ElementEvent =>
                            unselectElement({
                              element: ['Filter', filterKey, value],
                            });

                          if (!isMobile) {
                            fakeFilters.includes(value)
                              ? gtm(eventActionUnselect())
                              : gtm(eventActionSelect());
                          }
                        }}
                        className={cn('mr-2.5', styles.customCheckboxFilter)}
                      />
                      <span className="text-left">
                        <FormattedMessage
                          id={`cdp:filter:${filterKey}:${value}`}
                          defaultMessage={value}
                        />
                      </span>
                    </label>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      {Object.keys(availableFilterMap)
        .filter((filterKey) => availableFilterMap[filterKey].tags.length > 0)
        // Hide filters that we don't want to show to the user
        .filter((filterKey) => filterKey !== 'hidden')
        .filter((filterKey) => filterKey !== 'hero-filter')
        // Hide filters that are for the fake door test
        // @see https://petsdeli.atlassian.net/browse/PT-5632
        .filter((filterKey) => !filterKey.includes('fake'))
        .map((filterKey) => (
          <div key={filterKey} className="mb-6">
            <SmallCaps>
              <div className="mb-4 lg:mb-3">
                <FormattedMessage
                  id={'cdp:filter:' + filterKey}
                  defaultMessage={filterKey}
                />
              </div>
            </SmallCaps>
            <ul>
              {availableFilterMap[filterKey as CollectionFilter]?.tags.map(
                (value) => (
                  <li key={value} className="mb-4 text-sm last:mb-0">
                    <label
                      className={cn(
                        'flex items-center',
                        !isFilterAvailable(value, filterKey)
                          ? 'cursor-default text-gray-400'
                          : 'cursor-pointer text-gray-800'
                      )}
                    >
                      <input
                        type="checkbox"
                        checked={appliedFilter.includes(value)}
                        disabled={!isFilterAvailable(value, filterKey)}
                        onChange={() => {
                          toggleFilter(value);

                          const eventActionSelect = (): ElementEvent =>
                            selectElement({
                              element: ['Filter', filterKey, value],
                            });

                          const eventActionUnselect = (): ElementEvent =>
                            unselectElement({
                              element: ['Filter', filterKey, value],
                            });

                          if (!isMobile) {
                            appliedFilter.includes(value)
                              ? gtm(eventActionUnselect())
                              : gtm(eventActionSelect());
                          }
                        }}
                        className={cn('mr-2.5', styles.customCheckboxFilter)}
                      />
                      <span className="text-left">
                        <FormattedMessage
                          id={`cdp:filter:${filterKey}:${value}`}
                          defaultMessage={value}
                        />
                      </span>
                    </label>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
    </>
  );
};

export default FilterGroup;
