import IconBin from 'assets/icons/bin.svg';
import { useCollectionState } from 'modules/collection/state';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { selectElement } from 'tracking';
import { gtm } from 'tracking/gtm';
import FilterGroup from '../../filter-group';

const FilterLaptop: React.FC = () => {
  // Fake filters.
  // @see https://petsdeli.atlassian.net/browse/PT-5632
  const [fakeFilters, setFakeFilters] = useState<string[]>([]);
  const [exclusiveFilter, setExclusiveFilter] = useState<string[]>([]);

  const { appliedFilter, filterConfig, activeAllergyFilter, resetFilter } =
    useCollectionState();

  const numberOfActiveFilters =
    appliedFilter.length + activeAllergyFilter.length + fakeFilters.length;

  const handleReset = (): void => {
    resetFilter();
    setExclusiveFilter([]);
    setFakeFilters([]);
  };

  if (!filterConfig) return null;

  return (
    <div data-test="collection-filter">
      <div className="mb-4 flex items-center justify-between text-xs">
        <span className="text-sm font-medium leading-[21px]">
          <FormattedMessage id="cdp:filter:label" />
        </span>
        {numberOfActiveFilters > 0 && (
          <button
            className="flex shrink-0 items-center gap-2 text-xs font-medium leading-[18px] text-gray-600"
            onClick={() => {
              gtm(
                selectElement({
                  element: ['Filter', 'Unselect All'],
                })
              );
              handleReset();
            }}
          >
            <IconBin className="inline-block h-3 w-3" />
            <FormattedMessage id="cdp:resetfilters" />
          </button>
        )}
      </div>

      {filterConfig && (
        <div className="flex-auto px-0">
          {filterConfig && (
            <FilterGroup
              exclusiveFilter={exclusiveFilter}
              fakeFilters={fakeFilters}
              filterConfig={filterConfig}
              setExclusiveFilter={setExclusiveFilter}
              setFakeFilters={setFakeFilters}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FilterLaptop;
