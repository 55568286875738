import IconFilter from 'assets/icons/filter.svg';
import Button from 'components/button-new';
import Sheet from 'components/sheet';
import useLockBodyScroll from 'hooks/common/use-lock-body-scroll';
import useMobile from 'hooks/common/use-mobile';
import { FilterConfig } from 'interfaces/collection-filter';
import { useCollectionState } from 'modules/collection/state';
import { useAvailableFilterMap } from 'modules/collection/state/available-choices';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { selectElement, unselectElement } from 'tracking';
import { gtm } from 'tracking/gtm';
import FilterGroup from '../../filter-group';
import ResultCounter from '../../result-counter';

const FilterMobile: React.FC = () => {
  const {
    appliedFilter,
    collection,
    filterConfig,
    visibleProducts,
    activeAllergyFilter,
    resetFilter,
  } = useCollectionState();

  // Fake filters.
  // @see https://petsdeli.atlassian.net/browse/PT-5632
  const [fakeFilters, setFakeFilters] = useState<string[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [exclusiveFilter, setExclusiveFilter] = useState<string[]>([]);
  const availableFilterMap = useAvailableFilterMap(collection, filterConfig);
  useLockBodyScroll(isMenuOpen);

  const numberOfActiveFilters =
    // Add fake filters to the number of active filters
    // @see https://petsdeli.atlassian.net/browse/PT-5632
    appliedFilter.length + activeAllergyFilter.length + fakeFilters.length;

  // In case there are not other filters than the hero filter, we don't want to show the filter button
  const showFilterButton = (filterConfig: FilterConfig): boolean => {
    return (
      filterConfig &&
      !Array.isArray(filterConfig) &&
      typeof filterConfig === 'object' &&
      (Object.keys(filterConfig).length > 1 || !('hero-filter' in filterConfig))
    );
  };

  const findGroupKey = useCallback(
    (t: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const map = Object.entries(availableFilterMap).find(([_, conf]) =>
        (conf.tags as string[]).includes(t)
      );
      return map ? map[0] : String(undefined);
    },
    [availableFilterMap]
  );

  const isMobile = useMobile();
  const [prevAppliedFilter, setPrevAppliedFilter] = useState<string[]>([]);
  useEffect(() => {
    if (isMobile && !isMenuOpen) {
      const removedFilters = prevAppliedFilter.filter(
        (filter) => !appliedFilter.includes(filter)
      );

      const addedFilters = appliedFilter.filter(
        (filter) => !prevAppliedFilter.includes(filter)
      );

      removedFilters.forEach((filter) =>
        unselectElement({ element: ['Filter', findGroupKey(filter), filter] })
      );

      addedFilters.forEach((filter) =>
        selectElement({ element: ['Filter', findGroupKey(filter), filter] })
      );

      setPrevAppliedFilter(appliedFilter);
    }
  }, [appliedFilter, isMobile, prevAppliedFilter, isMenuOpen, findGroupKey]);

  // Replicate logic for tracking fake filters
  // @see https://petsdeli.atlassian.net/browse/PT-5632
  const [prevFakeFilters, setPrevFakeFilters] = useState<string[]>([]);
  useEffect(() => {
    if (isMobile && !isMenuOpen) {
      const removedFakeFilters = prevFakeFilters.filter(
        (filter) => !fakeFilters.includes(filter)
      );

      const addedFakeFilters = fakeFilters.filter(
        (filter) => !prevFakeFilters.includes(filter)
      );

      removedFakeFilters.forEach((filter) =>
        unselectElement({ element: ['Filter', findGroupKey(filter), filter] })
      );

      addedFakeFilters.forEach((filter) =>
        selectElement({ element: ['Filter', findGroupKey(filter), filter] })
      );

      setPrevFakeFilters(fakeFilters);
    }
  }, [fakeFilters, isMobile, prevFakeFilters, isMenuOpen, findGroupKey]);

  const handleClose = (): void => {
    setIsMenuOpen(false);
    const isActive = numberOfActiveFilters > 0;
    const eventElement = isActive ? 'Apply' : 'Close';
    gtm(
      selectElement({
        element: ['Filter', eventElement],
      })
    );
  };

  const handleReset = (): void => {
    resetFilter();
    setExclusiveFilter([]);
    setFakeFilters([]);
  };

  if (!filterConfig) return null;

  return (
    <>
      <div
        className="text-greay-600 flex items-center justify-between py-1.5 text-xs"
        data-test="collection-filter"
      >
        <ResultCounter />

        {filterConfig && showFilterButton(filterConfig) && (
          <div className="flex gap-2">
            <button
              className="relative"
              onClick={() => {
                setIsMenuOpen((prevState: boolean) => !prevState);
                gtm(
                  selectElement({
                    element: ['Filter', 'Toggle'],
                  })
                );
              }}
            >
              <IconFilter className="h-3 w-3" />
              {numberOfActiveFilters > 0 && (
                <div className=" absolute -right-1 top-0 mr-0.5 mt-px h-1.5 w-1.5 rounded-full bg-red-600" />
              )}
            </button>

            <button
              onClick={() => {
                setIsMenuOpen((prevState: boolean) => !prevState);
                gtm(
                  selectElement({
                    element: ['Filter', 'Toggle'],
                  })
                );
              }}
            >
              <FormattedMessage id="cdp:filter:label" />
            </button>
          </div>
        )}
      </div>

      <Sheet isOpen={isMenuOpen} onClose={handleClose}>
        {filterConfig && (
          <div className="mb-[60px] h-full flex-auto overflow-y-auto px-4">
            {filterConfig && (
              <FilterGroup
                exclusiveFilter={exclusiveFilter}
                fakeFilters={fakeFilters}
                filterConfig={filterConfig}
                setExclusiveFilter={setExclusiveFilter}
                setFakeFilters={setFakeFilters}
              />
            )}
          </div>
        )}

        <div className="absolute bottom-0 flex w-full gap-5 bg-white px-4 py-3 shadow">
          <Button
            size="base"
            layout="outline"
            styleDisabled={numberOfActiveFilters === 0}
            disabled={numberOfActiveFilters === 0}
            onClick={() => {
              gtm(
                selectElement({
                  element: ['Filter', 'Unselect All'],
                })
              );
              handleReset();
            }}
          >
            <FormattedMessage id="cdp:resetfilters" />
          </Button>

          <Button size="base" onClick={handleClose}>
            {numberOfActiveFilters ? (
              <>
                {visibleProducts.length + ' '}
                <FormattedMessage
                  id={
                    visibleProducts.length === 1
                      ? 'cdp:filter:showproduct'
                      : 'cdp:filter:showproducts'
                  }
                />
              </>
            ) : (
              <FormattedMessage id="cdp:filter:close" />
            )}
          </Button>
        </div>
      </Sheet>
    </>
  );
};

export default FilterMobile;
