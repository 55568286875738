import IconChevronRight from 'assets/icons/chevron-right-stroke.svg';
import MainIcon from 'assets/icons/main-icon.svg';
import Rating from 'assets/icons/rating.svg';
import cn from 'classnames';
import useImpressionTracking from 'hooks/gtm/use-impression-tracking';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { gtm, Gtm } from 'tracking/gtm';
import { ISectionAdsTileFields } from '../../../../global';
import Image from '../components/image';

interface PropTypes extends ISectionAdsTileFields {
  collectionTitle: string;
}

const AdsTile: React.FC<PropTypes> = ({
  adText,
  adTitle,
  backgroundColor,
  collectionTitle,
  desktopImage,
  link,
  mobileImage,
  position,
  quoteTile,
  urlParam,
}) => {
  const { pathname, query } = useRouter();

  const getBackgroundColor = (): string => {
    switch (backgroundColor) {
      case 'Brand-Blue':
        return '#1A335B';
      case 'Red-Sale':
        return '#FF5768';
      case 'None':
        return 'transparent';
      default:
        return '#1A335B';
    }
  };
  const $adsTileEl = React.useRef<HTMLDivElement>(null);

  useImpressionTracking({
    ref: $adsTileEl,
    impressionStoreKey: `${collectionTitle}-adtile`,
    impressionId: `${collectionTitle}-${position}`,
    trackingFn: (isIn) => {
      if (isIn) {
        gtm({
          group: Gtm.GroupName.AdTile,
          name: Gtm.CommonEventName.Impression,
          data: {
            impression: {
              list: collectionTitle,
              position: position + 1,
            },
            nonInteractive: true,
          },
        });
      }
    },
  });

  const trackClick = React.useCallback(() => {
    gtm({
      group: Gtm.GroupName.AdTile,
      name: Gtm.CommonEventName.Click,
      data: {
        click: {
          list: collectionTitle,
          position: position + 1,
        },
      },
    });
  }, [collectionTitle, position]);

  const scrollToElement = React.useCallback(() => {
    trackClick();
    if (window) {
      const headerEl = document.querySelector('#header') as HTMLDivElement;
      const headerHeight = headerEl ? headerEl.offsetHeight : 0;

      const scrollEl = document.querySelector(link || 'body') as HTMLDivElement;
      const scrollElPos = scrollEl ? scrollEl.offsetTop : 0;

      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: scrollElPos - headerHeight,
      });
    }
  }, [trackClick, link]);

  const adsTileChildren = (
    <div
      ref={$adsTileEl}
      data-test="ads-tile"
      className="relative h-full w-full"
    >
      <div
        style={{
          backgroundColor: getBackgroundColor(),
        }}
        className={cn(
          'absolute z-10 h-full w-full rounded-lg opacity-90',
          quoteTile && 'opacity-100'
        )}
      />

      {desktopImage && (
        <div className="hidden w-full md:block">
          <Image
            sizes="(min-width: 1024px) 310px, (min-width: 769px) 248px"
            className="rounded-lg"
            layout={'fill'}
            objectFit={'cover'}
            loading="lazy"
            {...desktopImage}
          />
        </div>
      )}

      {mobileImage && (
        <div className="md:hidden">
          <Image
            sizes="100vw"
            className="rounded-lg"
            layout={adTitle || adText ? 'fill' : 'responsive'}
            objectFit={'cover'}
            loading="lazy"
            {...mobileImage}
          />
        </div>
      )}

      {(adText || adTitle) && (
        <div
          style={{
            overflowWrap: 'anywhere',
          }}
          className="relative z-30 flex h-full w-full rounded-lg p-4 lg:py-6"
        >
          <div className="flex flex-col ">
            {quoteTile && (
              <div className="">
                <Rating className="mb-2 h-3 w-auto text-brand-star-yellow lg:-mt-2" />
              </div>
            )}
            <div className="flex flex-1 flex-col justify-between">
              <p
                className={cn(
                  'text-2xl font-black text-white lg:pb-4 lg:text-3xl',
                  quoteTile ? 'italic' : ' xl:text-4xl'
                )}
              >
                {adTitle}
              </p>
            </div>
            <div className="flex">
              <div className="text-white">{adText}</div>
              {quoteTile && <MainIcon className="w-6 text-transparent " />}
            </div>
          </div>
          {link && (
            <div className="-mr-2 flex md:hidden">
              <IconChevronRight className="h-auto w-5 text-white" />
            </div>
          )}
        </div>
      )}
    </div>
  );

  if (link) {
    if (link.charAt(0) === '#') {
      return (
        <button
          className="block w-full rounded-lg md:hover:shadow-xl"
          onClick={scrollToElement}
        >
          {adsTileChildren}
        </button>
      );
    }

    return (
      <Link href={link} passHref>
        <a
          onClick={trackClick}
          className="block w-full rounded-lg md:hover:shadow-xl"
        >
          {adsTileChildren}
        </a>
      </Link>
    );
  } else if (urlParam) {
    let _params = {};
    try {
      const searchParams = new URLSearchParams(urlParam);
      searchParams.forEach((value, key) => {
        _params[key] = value;
      });
    } catch (error) {
      console.log('urlParam is not valid url query', urlParam);
      _params = {};
    }

    return (
      <Link
        href={{
          pathname,
          query: {
            ...query,
            ..._params,
          },
        }}
        passHref
        replace
        scroll={false}
      >
        <a onClick={trackClick} className="block w-full md:hover:shadow-xl">
          {adsTileChildren}
        </a>
      </Link>
    );
  }

  return <>{adsTileChildren}</>;
};

export default AdsTile;
