import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import NavItem from 'components/nav-item';
import { possibleTags } from 'interfaces/collection-filter';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement, unselectElement } from 'tracking';
import { slugify } from 'utils/strings';
import { useCollectionState } from '../../state';

/**
 * Filter component for filtering products by allergen.
 * It is only shown on the collection page 'fur-hunde-unvertraeglichkeiten-allergien'
 */
const AllergiesFilter: React.FC = () => {
  const {
    activeAllergyFilter,
    collection,
    toggleActiveAllergyFilter,
    toggleAllergyFilter,
    toggleFilter,
  } = useCollectionState();

  const allergyTags = [
    'getreidefrei',
    'rind',
    'huhn',
    'pute',
    'ente',
    'fisch',
    'kartoffel',
    'lamm',
    'pferd',
    'ziege',
    'suskartoffel',
  ] as possibleTags[];

  const availableTagsSet = useMemo(
    () =>
      new Set(
        collection.products.flatMap((product) =>
          product.tags.map((tag) => slugify(tag))
        )
      ),
    [collection]
  );

  const filteredAllergyTags = allergyTags.filter((tag) =>
    availableTagsSet.has(tag)
  );

  const handleToggleAllergyFilter = useCallback(
    (tag: possibleTags) => {
      // Since the tag 'getreidefrei' is already present and used in products to identify them as wheat-free,
      // it was decided to use the current tag to display products without this allergen,
      // instead of excluding them as is the case with other allergens.
      // This allows us to avoid having to create and add a new tag such as 'getreide' to identify all products containing this allergen.
      if (tag === 'getreidefrei') {
        toggleFilter(tag);
        toggleActiveAllergyFilter(tag);
      } else {
        toggleAllergyFilter(tag);
        toggleActiveAllergyFilter(tag);
      }
    },
    [toggleActiveAllergyFilter, toggleAllergyFilter, toggleFilter]
  );

  return (
    <ImpressionTrackerNew element="Allergy Filter">
      <div className="mt-1.5 items-center lg:mb-0 lg:flex lg:px-0">
        <span className="mb-1.5 mr-3 inline-block text-sm lg:mb-0">
          <FormattedMessage id="cdp:allergy-filter:header" />
        </span>
        <div className="flex flex-wrap gap-2">
          {filteredAllergyTags
            .filter((tag) => availableTagsSet.has(tag))
            .map((tag) => (
              <NavItem
                key={tag}
                className="h-7 capitalize lg:h-10"
                selected={activeAllergyFilter.includes(tag)}
                onClick={() => {
                  handleToggleAllergyFilter(tag);
                  activeAllergyFilter.includes(tag)
                    ? gtm(
                        unselectElement({
                          element: ['Allergy Filter', tag],
                        })
                      )
                    : gtm(
                        selectElement({
                          element: ['Allergy Filter', tag],
                        })
                      );
                }}
              >
                <FormattedMessage id={`cdp:allergy-filter:${tag}`} />
              </NavItem>
            ))}
        </div>
      </div>
    </ImpressionTrackerNew>
  );
};

export default AllergiesFilter;
