import cn from 'classnames';

interface NavItemProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledStyle?: boolean;
  selected?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * NavItem is a functional component that renders a button with various styles based on the props.
 */
const NavItem: React.FC<NavItemProps> = ({
  children,
  className,
  disabled,
  disabledStyle,
  selected,
  onClick,
}) => (
  <button
    className={cn(
      'flex items-center justify-center gap-2 rounded-md px-3 py-2.5 text-sm leading-5 ring-1 ring-inset	ring-gray-300 lg:hover:ring-gray-800',
      selected && disabledStyle
        ? 'font-medium text-gray-800 line-through shadow-sm ring-2 ring-gray-800'
        : selected
          ? 'font-medium text-gray-800 shadow-sm ring-2 ring-gray-800'
          : disabledStyle
            ? 'text-gray-300 line-through ring-gray-100 lg:hover:ring-gray-100'
            : 'font-normal text-gray-600',
      className
    )}
    disabled={disabled}
    onClick={(event) => {
      event.preventDefault();
      onClick(event);
    }}
  >
    {children}
  </button>
);

export default NavItem;
