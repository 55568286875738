import ExperienceWrapper from 'components/experience-wrapper';
import { ISectionAdsTile } from 'global';
import AdsTile from 'modules/content-cms/sections/ads-tile';
import React, { memo } from 'react';

interface AdTileWrapperProps {
  title: string;
  entry: ISectionAdsTile;
}

/**
 * Fix for the bug where nt_experience is not captured.
 * Wrapping with memo fix the problem.
 * this problem should be fixed more properly.
 * @see : https://petsdeli.atlassian.net/browse/PT-5616
 */
const AdTileWrapper: React.FC<AdTileWrapperProps> = ({ title, entry }) => {
  return (
    <ExperienceWrapper
      entry={entry}
      component={(props) => {
        return <AdsTile {...props} collectionTitle={title} />;
      }}
    />
  );
};

export default memo(AdTileWrapper);
