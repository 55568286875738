import cn from 'classnames';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import { useCollectionState } from 'modules/collection/state';
import { useAvailableFilterMap } from 'modules/collection/state/available-choices';
import { FormattedMessage } from 'react-intl';
import HeroFilterButton from './filter-button';

interface HeroFilterContainerProps {
  className?: string;
}

/**
 * A component that renders the main filter options with images for a collection
 */
const HeroFilterContainer: React.FC<HeroFilterContainerProps> = ({
  className,
}) => {
  const { collection, filterConfig } = useCollectionState();
  const availableFilterMap = useAvailableFilterMap(collection, filterConfig);

  if (!('hero-filter' in availableFilterMap)) return null;

  return (
    <ImpressionTrackerNew element="Hero Filter">
      <div className={cn('flex items-center gap-5', className)}>
        <p className="hidden text-sm lg:block">
          <FormattedMessage id="landingpage:category-slider:title-EC" />:
        </p>
        <div className={cn('grid grid-cols-12 gap-2 lg:flex')}>
          {availableFilterMap['hero-filter']?.tags.map((filterName) => (
            <HeroFilterButton key={filterName} filterName={filterName} />
          ))}
        </div>
      </div>
    </ImpressionTrackerNew>
  );
};

export default HeroFilterContainer;
