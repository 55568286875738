import cn from 'classnames';
import Button from 'components/button-new';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import getPersonalizedRibbonText from 'components/personalized-ribbon/get-personalized-ribbon-text';
import ProductImpressionsTracker from 'components/product-impressions-tracker';
import { Entry } from 'contentful';
import { useSegmentationContext } from 'contexts/segmentation';
import { IProductPagesFields, ISectionAdsTile } from 'global';
import useMobile from 'hooks/common/use-mobile';
import { AbTestRibbonTextObj } from 'hooks/test/use-test-5830/testData';
import { CollectionProduct } from 'models/collection/collection';
import { useCollectionState } from 'modules/collection/state';
import dynamic from 'next/dynamic';
import { createRef, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import { isLegacyProductPageModel } from 'utils/contentful';
import AdTileWrapper from '../ad-tile-wrapper';
import AllergiesFilter from '../allergies-filter';
import FilterLaptop from '../collection-filter/laptop';
import FilterMobile from '../collection-filter/mobile';
import HeroFilterContainer from '../hero-filter/index.tsx';

const ProductTile = dynamic(() => import('components/product-card'));

interface IProductsGridProps {
  abTestRibbonTextObj?: AbTestRibbonTextObj;
  containerRef?: React.RefObject<HTMLDivElement>;
  contentfulData: Entry<IProductPagesFields>;
  customButton?: React.ReactNode;
  productsCardWhiteBackground?: boolean;
  productsLimit?: number;
  shouldShowAllergyFilter?: boolean;
  shouldShowBlackRibbon?: boolean;
  showFilter: boolean;
  showMoreButton?: boolean;
  trackingElementName?: string;
}

const TRACKING_NAME = 'Button Load More Products';

/**
 * This component is responsible for rendering the products grid of a collection.
 * It also renders the filter and the allergy filter.
 */
const ProductsGrid: React.FC<IProductsGridProps> = ({
  abTestRibbonTextObj,
  containerRef,
  contentfulData,
  customButton,
  productsCardWhiteBackground,
  productsLimit,
  shouldShowAllergyFilter,
  shouldShowBlackRibbon,
  showFilter,
  showMoreButton,
  trackingElementName,
}) => {
  const isMobile = useMobile();
  const {
    collection,
    limitOfProductsToShow,
    visibleProducts,
    setLimitOfProductsToShow,
    bestSellers,
  } = useCollectionState();

  useEffect(() => {
    if (productsLimit) {
      setLimitOfProductsToShow(productsLimit);
    } else {
      setLimitOfProductsToShow(undefined);
    }
  }, [productsLimit, setLimitOfProductsToShow]);

  const ref = containerRef ?? createRef<HTMLDivElement>();

  const adsTiles = useMemo(() => {
    const adtiles: Array<{ index: number; value: ISectionAdsTile }> = [];
    if (isLegacyProductPageModel(contentfulData)) {
      contentfulData.fields.collectionsAdsTiles?.map((tile) => {
        adtiles.push({
          // subtract as position begins with 1
          index: tile.fields.position - 1,
          value: tile,
        });
      });
    }

    return adtiles;
  }, [contentfulData]);

  const visibleElements = useMemo(() => {
    const sortedAdtiles = [...adsTiles].sort((a, b) => a.index - b.index);
    const _visibleProducts: Array<
      ISectionAdsTile | CollectionProduct | JSX.Element
    > = [
      ...visibleProducts,
      // limit by limitOfProductsToShow. if limitOfProductsToShow is undefined, slice returns all the elements
    ].slice(0, limitOfProductsToShow);

    for (let i = adsTiles.length - 1; i >= 0; i--) {
      const { index, value } = sortedAdtiles[i];
      if (index < _visibleProducts.length) {
        _visibleProducts.splice(index, 0, value);
      }
    }

    return _visibleProducts;
  }, [adsTiles, limitOfProductsToShow, visibleProducts]);

  const moreProductsWithoutVisibleProducts = useMemo(() => {
    return collection.products
      .filter(
        (product) =>
          !visibleProducts.find(
            (visibleProduct) => visibleProduct.id === product.id
          )
      )
      .slice(0, 9);
  }, [collection, visibleProducts]);

  const { segmentation, shouldOmitPersonalization } = useSegmentationContext();

  return (
    <>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-4" ref={ref}>
        {collection.products.length > 0 && showFilter ? (
          <>
            {/* laptop */}
            <div className="col-span-1 hidden lg:block">
              <div className="sticky top-0 ">
                <FilterLaptop />
              </div>
            </div>
            {/* mobile */}
            <div className="lg:hidden">
              <FilterMobile />
              {shouldShowAllergyFilter && <AllergiesFilter />}
              <HeroFilterContainer className="mt-2" />
            </div>
          </>
        ) : null}
        <div
          className="col-span-1 lg:col-span-3"
          data-test="cdp-collection-tile-wrapper"
        >
          <HeroFilterContainer className="mb-3 hidden lg:flex" />
          {shouldShowAllergyFilter && (
            <div className="mb-[30px] hidden lg:block">
              <AllergiesFilter />
            </div>
          )}
          <div
            className="grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-5 "
            data-test="cdp-collection-tile-wrapper"
          >
            {visibleElements.map((element, index: number) => {
              if (element['handle']) {
                const product = element as unknown as CollectionProduct;
                const id = getPersonalizedRibbonText(
                  bestSellers[product.id],
                  segmentation
                );
                return (
                  <ProductImpressionsTracker
                    element={trackingElementName ?? 'Product List'}
                    key={`${product.id}-${product.promoteVariantId}`}
                    list={collection.title}
                    position={index}
                    product={product}
                    trackingGroupName={collection.handle || ''}
                    collectionHandle={collection.handle}
                  >
                    <div data-test="product-tile-container" className={'flex'}>
                      <ProductTile
                        collectionTitle={collection.title}
                        personalizedRibbonText={
                          id &&
                          segmentation &&
                          abTestRibbonTextObj &&
                          abTestRibbonTextObj[segmentation] ? (
                            <>{abTestRibbonTextObj[segmentation]}</>
                          ) : shouldOmitPersonalization ? (
                            ''
                          ) : (
                            id && (
                              <FormattedMessage
                                id={id}
                                values={{
                                  b: (chunks) => <b>{chunks}</b>,
                                }}
                              />
                            )
                          )
                        }
                        position={index}
                        product={product}
                        starSize={isMobile ? 'small' : 'medium'}
                        shouldShowBlackRibbon={shouldShowBlackRibbon}
                        trackingElementName={trackingElementName}
                        whiteBackground={productsCardWhiteBackground}
                      />
                    </div>
                  </ProductImpressionsTracker>
                );
              } else if (element['sys']) {
                const adtile = element as unknown as ISectionAdsTile;
                return (
                  <AdTileWrapper
                    entry={adtile}
                    key={adtile.sys.id}
                    title={collection.title}
                  />
                );
              }
            })}
          </div>

          {!limitOfProductsToShow &&
            visibleProducts.length < 9 &&
            !!moreProductsWithoutVisibleProducts.length && (
              <div className="w-full">
                <div className="m-2 mt-4 border-t border-gray-200 pt-6 text-center text-xl font-black lg:mt-10 lg:pt-8">
                  <FormattedMessage id="cdp:similarproducts" />
                </div>
                <div
                  className="grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-5 "
                  data-test="collection-list"
                >
                  {moreProductsWithoutVisibleProducts.map(
                    (product, index: number) => (
                      <ProductImpressionsTracker
                        element="Product List"
                        key={`more-${product.id}-${product.promoteVariantId}`}
                        list={collection.title}
                        position={index}
                        product={product}
                        trackingGroupName={collection.handle || ''}
                        collectionHandle={collection.handle}
                      >
                        <div data-test="sometext3" className={'flex'}>
                          <ProductTile
                            product={product}
                            collectionTitle={collection.title}
                            position={index}
                          />
                        </div>
                      </ProductImpressionsTracker>
                    )
                  )}
                </div>
              </div>
            )}
          {showMoreButton && limitOfProductsToShow && !customButton && (
            <ImpressionTrackerNew element={TRACKING_NAME}>
              <div
                className={cn(
                  'my-4 flex justify-center',
                  isMobile && 'container'
                )}
              >
                <Button
                  size="base"
                  className="w-1/2 lg:w-1/3"
                  layout="outline"
                  onClick={() => {
                    setLimitOfProductsToShow(undefined);
                    gtm(
                      selectElement({
                        element: TRACKING_NAME,
                      })
                    );
                  }}
                >
                  <FormattedMessage id="cdp:standalone-cdp:load-more" />
                </Button>
              </div>
            </ImpressionTrackerNew>
          )}
          {customButton}
        </div>
      </div>
    </>
  );
};

export default ProductsGrid;
