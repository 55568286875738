import { SegmentationIds } from 'constants/segmentation';

const SegmentRibbonConfig: {
  [key in SegmentationIds]?: string;
} = {
  intolerance: 'personalized-ribbon-intolerance',
  overweight: 'personalized-ribbon-overweight',
  pickyeater: 'personalized-ribbon-pickyeater',
  digestion: 'personalized-ribbon-digestion',
  'skin-fur': 'personalized-ribbon-skin-fur',
  disease: 'personalized-ribbon-disease',
  welpe: 'personalized-ribbon-welpe',
  senior: 'personalized-ribbon-senior',
  bulldog: 'personalized-ribbon-bulldog',
  chihuahua: 'personalized-ribbon-chihuahua',
  dachshund: 'personalized-ribbon-dachshund',
  havanese: 'personalized-ribbon-havanese',
  poodle: 'personalized-ribbon-poodle',
  retriever: 'personalized-ribbon-retriever',
  'shepherd-dog': 'personalized-ribbon-shepherd-dog',
  spitz: 'personalized-ribbon-spitz',
  terrier: 'personalized-ribbon-terrier',
  herdingdog: 'personalized-ribbon-herdingdog',
  [SegmentationIds.CatPickyEater]: `personalized-ribbon-${SegmentationIds.CatPickyEater}`,
  [SegmentationIds.CatDigestion]: `personalized-ribbon-${SegmentationIds.CatDigestion}`,
  [SegmentationIds.CatOverweight]: `personalized-ribbon-${SegmentationIds.CatOverweight}`,
  [SegmentationIds.Sterilized]: `personalized-ribbon-${SegmentationIds.Sterilized}`,
  [SegmentationIds.Outdoor]: `personalized-ribbon-${SegmentationIds.Outdoor}`,
  [SegmentationIds.Kitten]: `personalized-ribbon-${SegmentationIds.Kitten}`,
  [SegmentationIds.CatSenior]: `personalized-ribbon-${SegmentationIds.CatSenior}`,
};

const getPersonalizedRibbonText = (
  bestSellingSegments: string[],
  segmentation?: string
): string | undefined => {
  if (!segmentation || !bestSellingSegments || !bestSellingSegments.length) {
    return undefined;
  }

  const matchedSegment = bestSellingSegments.find(
    (segment) => segment === segmentation
  );

  if (matchedSegment && SegmentRibbonConfig[matchedSegment]) {
    return SegmentRibbonConfig[matchedSegment] || undefined;
  }

  return undefined;
};

export default getPersonalizedRibbonText;
