import { FilterConfig } from 'interfaces/collection-filter';
import { Collection } from 'models/collection/transformCollection';
import { useEffect, useMemo, useState } from 'react';
import { slugify } from '../../../utils/strings';

/**
 * Filter out applicable tags as filter values for CDP based on collection from filterConfig.tags,
 */
export const useAvailableFilterMap = (
  collection: Collection,
  filterConfig?: FilterConfig
): FilterConfig => {
  const [availableChoices, setAvailableChoices] = useState<FilterConfig>(
    filterConfig || {}
  );

  const convertedTags = useMemo(() => {
    const slugifyiedTags = collection.products
      .map((product) => {
        return product.tags.map((tag) => slugify(tag));
      })
      .flat();
    // return only unique
    return Array.from(new Set(slugifyiedTags));
  }, [collection]);

  useEffect(() => {
    if (!collection || !collection.products) {
      setAvailableChoices({});
      return;
    }

    if (!filterConfig) {
      return;
    }

    const _config = { ...filterConfig };
    for (const key of Object.keys(_config)) {
      if (_config[key]) {
        const _tags = _config[key]?.tags || [];
        // use as any since while we know that this wouldn't be undefined
        (_config[key] as any).tags = _tags.filter((tag) =>
          convertedTags.includes(tag)
        );
      }
    }

    setAvailableChoices(_config);
  }, [collection, convertedTags, filterConfig]);

  return availableChoices;
};
